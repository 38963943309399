import React from 'react';

import Carousel, { slidesToShowPlugin, arrowsPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import right from './assets/images/arrowRight.svg';
import left from './assets/images/arrowLeft.svg';

import CarrouselContent from './CarrouselContent';
import { executives } from './executives';

import './styles.scss';

const OperadoraCarrousel = () => {
  const ejecutivosInfo : any [] = [];

  const ejecutivosSlides = (executives : any) => { 

    executives.map((slide : any) => {
      ejecutivosInfo.push(
      <>
        <CarrouselContent
          id={slide.id}
          name={slide.name}
          position={slide.position}
          bio={slide.bio}
          photo={slide.photo}
        />
      </>
      )
    })

    return ejecutivosInfo;
  }

  const contentCarousel = () => {
    if (typeof window !== 'undefined') {
      return (
      <Carousel 
        slides={ejecutivosSlides(executives)}
          plugins={[
            'infinite',
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: 1
              }
            },
            {
              resolve: arrowsPlugin,
              options: {
                arrowLeft: <img src={left} alt="left" className='carrousel-arrows'/>,
                arrowRight: <img src={right} alt="right" className='carrousel-arrows'/>,
                addArrowClickHandler: true,
                draggable: true
              }
            }
        ]}/>
        )
      }
    }
  return (
    <div className='uwu-carrousel'>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div>
              <h2 className='text-light'>Ejecutivos</h2>
            </div>
            <div>
              { contentCarousel() }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OperadoraCarrousel;