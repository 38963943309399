import React from 'react';

import CTAImg from './CTAImage.png';
import './styles.scss';

const SupportCTA: React.FC = ({children}) => (
  <section className="my-5 py-5">
    <div className="container">
      <div className="row">
        <div className="Support-CTA col-xl-10 offset-xl-1 col-md-12 col-12">
          <div className="row align-items-center">
            <div className="Support-CTA__container offset-xl-1 col-xl-5 col-md-6 col-12">
              <div className='text-center'>
                <p className="Support-CTA__text">
                  ¿Preguntas? Hazlas en:
                </p>
                <a className="Support-CTA__link" href="mailto:soporte@kushkipagos.com">
                  soporte@kushkipagos.com
                </a>
              </div>
            </div>
            <div className="col-xl-6 col-md-6 col-12">
              <div className="text-center">
                <img src={CTAImg}  alt="Support" className="img-fluid"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</section>
)

export default SupportCTA;
