import React from 'react';

import DocumentIcon from './Document.svg';
import ShareIcon from './Share.svg';

import './styles.scss';

interface OperadoraCardProps {
  key: string;
  title: string;
  text?: string;
  document?: string;
  link: string;
  date?: string;
  tag: string;
  query: string;
}

const OperadoraCard: React.FC<OperadoraCardProps> = ({title, text, date, link, document, tag, query}) => (
  query === tag ? (
  <>
    <div className={`card ${text == null ? "w-50" : "w-100"}`} key={title} id={tag}>
      <div className="card-body">
        <div className="container text-left">
          <div className="cardIcon">
            {link == null ? <img src={DocumentIcon}  alt="TextIcon"/> : <img src={ShareIcon} alt="ShareIcon"/>}
          </div>
          <h5 className="card-title">{title}</h5>
            {
            text ? text : 
              <>
                <div className="row">
                    <div className="col-10 align-self-start">
                      { (link != null && date) ? null : 
                        <small className="card-subtitle mb-2 text-muted">{date}</small>
                      }
                    </div>

                  <div className="col-2 align-self-end">
                    <button className="btn btn-sm CTA-button" >
                      {
                        link === null ? 
                        <a href={document} target={'_blank'} download> Descargar </a> :
                        <a href={link} target={'_blank'}> Visitar </a> 
                      }
                    </button>
                  </div>
                </div>
              </>
            }                    
        </div>
      </div>
    </div>
  </>
  ) : null
);

export default OperadoraCard;