import React from 'react';

import IndexLayout from '../layouts';

import Footer from '../components/Footer/Footer';
import SEO from '../components/SEO/SEO';

import OperadoraHero from '../components/Operadora/OperadoraHero/OperadoraHero';
import OperadoraSections from '../components/Operadora/OperadoraSections/OperadoraSections';
import SupportCTA from '../components/Operadora/SupportCTA/SupportCTA';

import { LocaleTypes } from '../data/locales';

interface OperadoraPageProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const OperadoraPage: React.FC<OperadoraPageProps> = ({ pathContext, location }) => {

  const content = () => (
    <>
      <SEO titleId="Kushki Operadora S.A." />
      <OperadoraHero>
        <h1>Kushki Operadora S.A.</h1>
        <p className="lead">Cumplimiento normativo. </p>
      </OperadoraHero>
      <OperadoraSections/>
      <SupportCTA/>
      <Footer theme="gray" />
    </>
  );

  return <IndexLayout navbarTheme="light" render={ content } locale={pathContext.localeCode} location={location} />
}

export default OperadoraPage;
