import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery} from 'gatsby';

import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import OperadoraCard from '../OperadoraCard/OperadoraCard';
import OperadoraCarrousel from '../OperadoraCarrousel/OperadoraCarrousel'

import './styles.scss';

const OperadoraSections: React.FC = () => {
  const { allContentfulOperadora } = useStaticQuery(
    graphql`
    query ($formatString: String = "MMM-DD-YYYY"){
        allContentfulOperadora {
          edges {
            node {
              document {
                localFile {
                  url
                }
              }
              tag
              title
              link
              id
              text {
                content {
                  content {
                    value
                    nodeType
                  }
                }
              }
              updatedAt(formatString:$formatString)
            }
          }
        }
      }
    `,
  );

  const documentsOperadora = allContentfulOperadora.edges.map((node : any) => node);
  

  const tabs = ["portfolio", "company", "politics"];

  const categories = [{tab: "portfolio", name: "Portafolio"}, {tab: "company", name: "Nuestra compañía"}, {tab: "politics", name: "Políticas"}];

  const [selectedTab, setSelectedTab] = useState<string>(tabs[0]);

  let handleSelectedTab: any;

  useEffect(() => {
    handleSelectedTab = (document: string) => { setSelectedTab(document) };
  }, [selectedTab]);

  function filtrarDuplicadosPorTitulo(filtrados: any[]) {
    const titulosUnicos: string[] = [];
    const resultado: any[] = [];
  
    filtrados.forEach((filtrado) => {
      // Verificar si el título ya está en la lista de títulos únicos
      const tituloExistente = titulosUnicos.find((titulo) => titulo === filtrado.node.title);
  
      if (!tituloExistente) {
        titulosUnicos.push(filtrado.node.title);
        resultado.push(filtrado);
      }
    });
  
    return resultado;
  }

  return (
    <section className="operadora__section pt-layout-5">
      <div className="container">
        
        <Tabs>
          <TabList>
            {
              categories.map((category : {tab: string, name: string}) => {
                return (
                  <Tab key={category.tab} onClick={() => handleSelectedTab(category.tab)}><br className="d-block d-sm-none"/>{category.name}</Tab>
              )})
            }
          </TabList>

          <TabPanel>
            <section className="row py-layout-4">
              <div className="container">
                {
                filtrarDuplicadosPorTitulo(documentsOperadora).map((documentsOperadora: any) => {
                  return (
                  <OperadoraCard
                    tag={selectedTab}
                    query={documentsOperadora.node?.tag}
                    key={documentsOperadora.id}
                    title={documentsOperadora.node?.title}
                    text={documentsOperadora.node?.text?.content[0]?.content[0]?.value}
                    link={documentsOperadora.node?.link}
                    document={documentsOperadora.node?.document?.localFile?.url}
                    date={documentsOperadora.node?.updatedAt}
                  />
                  )})
                }
            </div>
          </section>
          </TabPanel>
          <TabPanel>
            <section className="py-layout-4">
              <div className="container">
                {
                filtrarDuplicadosPorTitulo(documentsOperadora).map((documentsOperadora: any) => {
                  return (
                  <OperadoraCard
                    tag={selectedTab}
                    query={documentsOperadora.node?.tag}
                    key={documentsOperadora.id}
                    title={documentsOperadora.node?.title}
                    text={documentsOperadora.node?.text?.content[0]?.content[0]?.value}
                    link={documentsOperadora.node?.link}
                    document={documentsOperadora.node?.document?.localFile?.url}
                    date={documentsOperadora.node?.updatedAt}
                  />
                  )})
                }
              <OperadoraCarrousel/>
            </div>
          </section>
          </TabPanel>
          <TabPanel>
            <section className="py-layout-4">
              <div className="container">
                {
                filtrarDuplicadosPorTitulo(documentsOperadora).map((documentsOperadora: any) => {
                  return (
                  <OperadoraCard
                    tag={selectedTab}
                    query={documentsOperadora.node?.tag}
                    key={documentsOperadora.id}
                    title={documentsOperadora.node?.title}
                    text={documentsOperadora.node?.text?.content[0]?.content[0]?.value}
                    link={documentsOperadora.node?.link}
                    document={documentsOperadora.node?.document?.localFile?.url}
                    date={documentsOperadora.node?.updatedAt}
                  />
                  )})
                }
            </div>
          </section>
          </TabPanel>
          <TabPanel>
            <section className="py-layout-4">
              <div className="container">
                {
                filtrarDuplicadosPorTitulo(documentsOperadora).map((documentsOperadora: any) => {
                  return (
                  <OperadoraCard
                    tag={selectedTab}
                    query={documentsOperadora.node?.tag}
                    key={documentsOperadora.id}
                    title={documentsOperadora.node?.title}
                    text={documentsOperadora.node?.text?.content[0]?.content[0]?.value}
                    link={documentsOperadora.node?.link}
                    document={documentsOperadora.node?.document?.localFile?.url}
                    date={documentsOperadora.node?.updatedAt}
                  />
                  )})
                }
            </div>
          </section>
          </TabPanel>
        </Tabs>
      </div>
    </section>
  )
};

export default OperadoraSections;