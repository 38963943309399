import haryk from '../OperadoraCarrousel/assets/images/haryk.jpg';
import rodrigo from '../OperadoraCarrousel/assets/images/rodrigo.jpg';
export const executives = [
  {
    "id": "7fdc4b8b-4137-5224-8815-398a2fed1bed",
    "name": "Miguel Aguilar Gálvez",
    "position": "Head de Procesamiento",
    "bio": "Miguel Aguilar Gálvez es Bachiller en Ingeniería Electrónica egresado de la Universidad Nacional de Ingeniería (U.N.I.) de Lima, Perú, con más de 26 años de experiencia en medios de pago, antes de incorporarse al equipo de KUSHKI, trabajó en diferentes empresas relacionadas a este rubro: grupo Telefónica del Perú (Telefónica Servicios Financieros, Telefónica Data y Telefónica empresas) como procesador de VisaNet Perú; en VisaNet Perú (hoy Niubiz) adquirente de tarjetas VISA, Procesos de Medios de Pagos (hoy Izipay) adquirente de tarjetas Mastercard, y Mastercard Worldwide, oficina Peru. Tiene amplia experiencia en el desarrollo de aplicaciones e implementación de productos de pagos con tarjetas de crédito, débito y prepago de las marcas VISA, Mastercard y American Express, habiendo participado y liderado iniciativas del mercado peruano como el lanzamiento de transacciones de compra con tarjetas con chip, contactless, BIN-2 de Mastercard, autenticación 3DS para e-commerce, pagos con QR presentado por el comercio, etc. En su rol de Head de Procesamiento en KUSHKI, lideró los proyectos de certificación adquirente con VISA y Mastercard para Perú y Colombia, y hoy en día como Gerente Regional de Procesamiento y Adquirencia está a cargo, principalmente, de la relación técnico operativa con estas marcas para la región LATAM, así como de la gestión de las inciativas para implementación de nuevos productos.",
    "photo": "https://images.ctfassets.net/51xdmtqw3t2p/4gWTzJy6nztRLgf3Y9OaBO/7fd0043bc663610136ccc7d001617c9c/Miguel_Aguilar_Ga_lvez.jpg"
  },
  {
    "id": "7c34e860-b6af-5ed7-a22f-bffca5064639",
    "name": "Fernando Bermúdez",
    "position": "VP Legal",
    "bio": "Fernando es abogado y  politólogo de la Universidad de los Andes, Colombia. Cuenta con un máster en leyes de The London School of Economics and Political Science (LSE). \n\nCerca de 10 años atrás comenzó a forjar su vida profesional en diferentes bufetes legales, desempeñándose en diferentes cargos hasta alcanzar el rango de Senior Associate en Muñoz Tamayo & Asociados.",
    "photo": "https://images.ctfassets.net/51xdmtqw3t2p/2LhUNSYOY4a6uR8aIFwMNt/1d298370ded7b6af2d2d3c082bcfec53/K-231.jpg"
  },
  {
    "id": "8fd46352-e2a8-58c4-836a-5a0c04acafee",
    "name": "Eduardo Cantón",
    "position": "Chief Governance & Compliance Officer",
    "bio": "Como responsable del Gobierno Corporativo tiene a su cargo la gestión de Riesgos Operativos y Procesos, Seguridad de la Información y el área de Compliance cuyo enfoque es brindar transparencia, certeza y confianza a las contrapartes.\n\nEduardo tiene amplia experiencia en los sistemas electrónicos de pago tanto de la perspectiva comercial, de producto, tecnología, riesgo y cumplimiento. Algunos de los proyectos implementados por Eduardo en la industria de pagos fueron, la primera implementación de 3D Secure en Latinoamérica de la mano de Banco Santander, Yahoo y Visa, el primer proyecto de monedero electrónico denominado Visacash, precursor del chip que opera hoy en día en las tarjetas de pago, el nacimiento del SVL Sistema de Validación en Línea y la colaboración activa entre bancos y comercios para mejorar las tasas de aceptación.\n\nEduardo cuenta con un MBA por parte del IPADE (Instituto Panamericano en Alta Dirección de Empresa, un CISSP (Certified Information System Security Professional) por el ITESM (Instituto Tecnológico y de Estudios Superiores de Monterrey) y una maestría en Ingeniería Financiera por la UVM (Universidad del Valle de México).",
    "photo": "https://images.ctfassets.net/51xdmtqw3t2p/6uwNH2TdENUIKLfMGXGs8f/0e62bc065953914899a3f1267aca7827/Eduardo_Canto_n49__1___1_.JPG"
  },
  {
    "id": "3cb31e75-a40a-54ae-b878-5b0c0a90f88c",
    "name": "Alejandro Porteny",
    "position": "Chief Financial Officer",
    "bio": "BSBA en Finanzas de Suffolk University (Boston, MA) arranco su carrera en Private Equity antes de entrar a la industria de procesamiento de pagos en el 2011. Lidero el area de finanzas en la Fintech Leaf en Estados Unidos y la venta exitosa de esta empresa a Heartland Payment Systems (HPY) empresa publica en EEUU. En el 2019 fue nombrado CFO de Kushki.",
    "photo": "https://images.ctfassets.net/51xdmtqw3t2p/4dyCNcJsC6Pde5MNfK33Mm/f21a85c56d2e85591efa9f258c7bbd0e/AP-115.jpg"
  },
  {
    "id": "05aed16b-548c-598b-973c-ade70d831204",
    "name": "Haryk Pujado",
    "position": "Gerente de Riesgo Operacional y Procesos CL",
    "bio": `Haryk Pujado es Ingeniero de Ejecución en Computación e Informática de la Universidad
    de Santiago. Cuenta con un Diplomado en Gestión de Ciberseguridad en la Universidad de
    Santiago. Tiene más de 20 años de experiencia en la industria bancaria, durante 15 años
    en Banco Bci, ocupó varios roles de liderazgo en el área de Tecnologías de la Información
    (TI), Riesgo de Crédito e Inteligencia de Negocio. Posteriormente, trasladó su experiencia
    al campo del Riesgo Operacional, donde trabajó en Scotiabank 3 años, y luego en Banco
    Internacional, ocupando el cargo de Jefe de Riesgo Operacional y Continuidad de Negocio
    durante 3 años también. En estas posiciones, fue responsable de identificar, evaluar y
    mitigar los riesgos operacionales de la organización, así como de desarrollar planes de
    continuidad del negocio para garantizar la resiliencia ante posibles eventos adversos. Hoy
    en día como Gerente de Riesgo Operacional y de Seguridad de la Información en Kushki
    Chile, tiene el rol de liderar y dirigir estratégicamente las iniciativas relacionadas con la
    gestión de riesgos operacionales y la protección de la información sensible de la compañía,
    impulsando la excelencia operativa, mitigar los riesgos y fortalecer la seguridad de la
    información, contribuyendo así al crecimiento sostenible y exitoso de la empresa`,
    "photo": haryk
  },
  {
    "id": "be18964a-777e-5b12-9c65-02ff41c72142",
    "name": "Rodrigo Quijada",
    "position": "Country Manager de Chile",
    "bio": "Rodrigo es ingeniero comercial de la Universidad Diego Portales de Chile, con un posgrado en Desarrollo Directivo de ESE Business School. Cuenta con conocimientos en Medios de Pago, negocios B2B, BC2, innovación, ecommerce y gestión de proyectos.\n\nTiene una amplia trayectoria en la industria financiera, banca y medios de pago, después de trabajar en compañías líderes de la industria y mercado como Citibank, BCI y Transbank.\n\nSe especializa en la generación y desarrollo de estrategias comerciales que generan importantes resultados tanto a nivel de ingresos, participación de mercado, nuevos clientes y negocios, tasas de satisfacción y de recomendación de marca.",
    "photo": "https://images.ctfassets.net/51xdmtqw3t2p/52kJbHM1rtwMfH1aHHDEeW/858d88e6f9a1ad138c8d5ca915c38dd3/Copy_of_K-268.jpg"
  },
  {
    "id": "919b9935-5010-5ea3-a9de-b176578455e3",
    "name": "Rodrigo Paredes",
    "position": "Head de Adquirencia & Procesamiento",
    "bio": "Rodrigo Paredes es Ingeniero Civil Industrial Universidad de Chile, con amplia experiencia en el sistema financiero en diferentes bancos, multinacionales y retail, entre ellos; Citibank, BBVA, HSBC, Scotiabank, Cencosud, en posiciones gerenciales en áreas de producto, estrategia comercial y clientes. Ha liderado el Lanzamiento de medios de pago digitales y físicos, propuestas de valor y programas de lealtad para todos los segmentos de mercado. Posee amplia y exitosa experiencia en el mundo emisor y adquirente.",
    "photo": rodrigo
  },
  {
    "id": "377b76e5-ce9b-5261-9f24-7b62cd58fc89",
    "name": "Fabian Duran",
    "position": "Gerente Compliance",
    "bio": "Contador Auditor, Magister en Dirección y Gestión (c); Diplomado en Gestión de las Personas; Diplomado en Liderazgo y Coaching; Diplomado en Compras y Contrataciones Públicas y Postítulo en Control de Gestión. Con más  de 11 años de experiencia en Cargos de Alto Nivel en Auditoria & Compliance, liderando certificaciones e implementaciones de  Modelos de Prevención de Delitos en entidades públicas/privadas. Durante 5 años fue parte de los 8 profesionales expertos de la Unidad de Análisis Financiero de Chile (UAF), con la misión de garantizar la integridad financiera del País, fiscalizando el cumplimiento normativo en la materia de Prevención de LA|FT|PADM de más de 8000 Sujetos Obligados de distintas industrias.\n\nActualmente es Gerente Compliance & Encargado de Prevención de Delitos de Kushki Chile y Perú.",
    "photo": "https://images.ctfassets.net/51xdmtqw3t2p/6d0aHETH0hU4dfhxLeX7KN/2fd10ad0596da7301aab8d30dc17367b/DSC_5171.jpg"
  },
  {
    "id": "59f98457-7507-572f-87fe-429e8359d95f",
    "name": "Carolina Alvarado",
    "position": "Directora legal",
    "bio": "Carolina Alvarado es Abogada y Magíster en Derecho de la Empresa de la Universidad Adolfo Ibáñez. Se ha desmpeñado en grandes firmas de abogados durante la primera mitad de su carrera, entre ellas Cariola Díez, Pérez-Cotapos, y posteriormente en la industria financiera, banca, AFP y AGF. Ha participado en 2 procesos ante la CMF para la creación de Operadores de Tarjetas de Pagos: Getnet y Kushki Operadora.",
    "photo": "https://images.ctfassets.net/51xdmtqw3t2p/oXkiJi6UwoPc8FBVn2Jor/ccf42e16ee8556d4ca58d733c5b2974d/Carolina_Alvarado.jpg"
  }
]