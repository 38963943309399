import React from 'react'

import './styles.scss';

interface CarrouselContentProps {
  id: string;
  name: string;
  position: string;
  bio: string;
  photo: string;
}

const CarrouselContent: React.FC<CarrouselContentProps> = ({id, name, position, bio, photo}) => (
  <>
    <div className="card card-carrousel mt-6">
      <div className="col-md-3">
        <img src={photo} className="card__carrousel-image" alt={name} />
      </div>
      <div className="col-md-9">
        <div className='card-content' id={id}>
          <h2 className='text-primary text-white'>{name}</h2>
          <h6 className='text-primary text-white'>{position}</h6>
          <p className='text-primary text-white'>{bio}</p>
        </div>
      </div>
    </div>
  </>
);

export default CarrouselContent;